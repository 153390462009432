import PropsConfig from  './config/props';
import {paginationConfig} from './config/pagination';

import headerEditMixin from './headerEdit.js';
import MethodsMixin from  './config/methods';
import {filterDate} from './filter.js';

export default {
  mixins: [ headerEditMixin, MethodsMixin ],
  props: {
    ...PropsConfig,
    tableData: {
        type: Array,
        default: function() {
            return [];
        }
    },
    tableColumn: {
        type: Array,
        default: function() {
            return [];
        }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
      return {
        paginationConfig,
        page: {
          total: this.total,
          currentPage: 1,
          pageSize: 200,
        }
      };
  },
  mounted() {
    this.columnDrop();
    this.$nextTick(()=>{
      this.$emit('tableMounted')
    })
  },
  methods: {
    linkEvent(type, params) {
      this.$emit(type, params)
    },
    filterMethods(params) {
      let {cellValue, row, column} = params;
      if (column.title.indexOf('日期') >  -1) {
        cellValue = filterDate(cellValue)
      }
      return cellValue
    }
  }
};
