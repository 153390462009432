export default {
  methods:{
    /**
     * @description 单选事件
     * @param {Object} row 当前行数据
    */
    radioChangeEvent ({ row }) {
      // console.log('单选事件', row);
      this.$emit('radioChangeEvent', row)
    },
    /**
     * @description 行选中事件
     * @param {Object} row 当前行数据
    */
    currentChangeEvent ({ row }) {
      // console.log('行选中事件', row);
      this.$emit('currentChangeEvent', row)
    },
    /**
     * @description 全选 / 反选
     * @param {Object<checked, records>}
     * @param {Boolean} checked 是否选中
     * @param {Array} records 勾选/取消的数据
    */
    selectAllEvent ({ checked, records }) {
      // console.log(checked ? '所有勾选事件' : '所有取消事件', records)
      this.$emit('selectAllEvent', { checked, records })
    },
    /**
     * @description 单个勾选 / 反选
     * @param {Object<checked, records>}
     * @param {Boolean} checked 是否选中
     * @param {Array} records 勾选/取消的数据
    */
    selectChangeEvent ({ records, checked, row }) {
      this.$emit('selectChangeEvent', { checked, records })
    },
    /**
     * @description 排序获取
     * @param {Object<sortList>}
    */
    sortChangeEvent({ column, property, order }) {
      // console.info('排序获取', property, order)
      this.$emit('selectChangeEvent', { property, order })
    },
    /**
     * @description 当鼠标范围选择结束时会触发的事件
    */
    selectCheckboxRangeEnd({ records, reserves, $event }) {
      // console.log('鼠标范围选择结束时', records, reserves)
      this.$emit('selectCheckboxRangeEnd', { records, reserves })
    },
    /**
     * @description 分页数据变化
     * @param {Number} currentPage 当前页
     * @param {Number} pageSize 一页多少条
    */
    handlePageChange({ currentPage, pageSize }){
      this.page.currentPage = currentPage;
      this.page.pageSize = pageSize;
      this.$emit('handlePageChange', { currentPage, pageSize })
    },
    /**
     * @description 单元格被双击时会触发该事件
     * @params { row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }
    */
    cellDBClick({row}) {
      this.$emit('cell-dbclick', row)
    }
  }
}