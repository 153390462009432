
import Sortable from 'sortablejs';

export default {
  data () {
    return {
      columns: this.tableColumn,
    }
  },
  methods: {
    /**
     * @description 列拖拽
    */
    columnDrop() {
      const that = this;
      const wrapperTr = document.querySelector('.vxe-table--header .vxe-header--row')
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: async (evt) => {
          const {newIndex, oldIndex, event} = evt;
          //const dropColumn = that.tableColumn.map(item=>item);
          const {tableColumn} = that.$refs.vxeTable.getTableColumn()

          const oldItem = tableColumn[oldIndex];
          tableColumn.splice(oldIndex, 1);
          tableColumn.splice(newIndex, 0, oldItem);

          that.$nextTick(()=>{
            that.$refs.vxeTable.reloadColumn(tableColumn);
            that.$emit('headerDroped', tableColumn)
          })
        }
      })
    },
  }
}