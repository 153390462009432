/**
 * @file props 属性
*/

export default {
  /**
   * @description 显示内容方向
   * @augments <String> center | left | right
   * @default <String> left
  */
  allAlign: {
    type: String,
    default: 'left'
  },
  /**
   * @description 是否显示加载中
   * @default <Boolean> false
  */
  loading: {
    type: Boolean,
    default: false
  },
  /**
   * @description 关于toltip的配置
   * @default <Object> {}
  */
  tooltipConfig: {
    type: Object,
    default: () => {}
  },
  /**
   * @description 是否展示头部
   * @default <Boolean> true
  */
  showHeader: {
    type: Boolean,
    default: true
  },
  /**
   * @description 列宽拖动
   * @default <Boolean> true
  */
  resizable: {
    type: Boolean,
    default: true
  },
  /**
   * @description 自动监听父元素的变化去重新计算表格（对于父元素可能存在动态变化、显示隐藏的容器中、列宽异常等场景中的可能会用到
   * @default <Boolean> false
  */
  autoResize: {
    type: Boolean,
    default: false
  },
  /**
   * @description 表格的高度
   * @augments <> 可选值 %, px
  */
  height: {
    type: [Number, String],
    default: ''
  },
  /**
   * @description 表格的最大高度
   * @augments <> 可选值 %, px
  */
  maxHeight: {
    type: [Number, String],
    default: ''
  },
  /**
   * @description 是否要高亮当前列
   * @default <Boolean> false
  */
  highlightCurrentColumn: {
    type: Boolean,
    default: true
  },
  /**
   * @description 是否要高亮当前行
   * @default <Boolean> false
  */
  highlightCurrentRow: {
    type: Boolean,
    default: true
  },
  /**
   * @description radio 筛选的配置
  */
  radioConfig: {
    type: Object,
    default: () => {}
  },
  /**
   * @description checkbox 筛选的配置 可配置能否备选或者根据列是否符合某条件来选
   * @augments {Object} 某列能进行鼠标滑动选中,并高亮 <eg: checkbox-config="{labelField: 'name', highlight: true, range: true}">
  */
  checkboxConfig: {
    type: Object,
    default: () => {
      return {trigger: 'row', highlight: true, range: true}
    }
  },
  /**
   * @description 排序的配置
   * @augments {Object} 某列能进行鼠标滑动选中,并高亮 <eg: checkbox-config="{labelField: 'name', highlight: true, range: true}">
  */
  sortConfig: {
    type: Object,
    default: () => {}
  },
  /**
   * @description 行编辑的配置 
  */
  editConfig: {
    type: Object,
    default: () => {}
  },
  /**
   * @description 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
  */
  keepSource: {
    type: Boolean,
    default: false
  }
}