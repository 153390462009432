/***
 * @description 不够补0
*/
function addZero(val) {
  return val < 10 ? `0${val}` : val;
}

export const filterDate = function (params) {
  let year = new Date(params).getFullYear(),
  month = new Date(params).getMonth() + 1,
  day = new Date(params).getDate(),
  hour = new Date(params).getHours(),
  minutes = new Date(params).getMinutes(),
  seconds = new Date(params).getSeconds();
  
  return params ? `${year}-${addZero(month)}-${addZero(day)} ${addZero(hour)}:${addZero(minutes)}:${addZero(seconds)}` : '';
}